@import '/apps/wt/webtools/sparta_build_server/tmp/EE81542B/artifacts/components/utilities/global/sparta-style-utility/4.9.4/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;




[data-sparta-container] .#{$moduleNameSpace} {
  border-top: 1px solid $color-gray-s05;
  margin-top: $spacing-40;
  padding-top: $spacing-20;

  @media #{$medium-up} {
    border-top: none;
    margin-bottom: $spacing-20;
  }

  .flex-container {
    display: flex;
    margin-bottom: $spacing-20;

    @media #{$medium-up} {
      margin-bottom: 0;
    }

    .spa-input-hint {
      width: auto;
    }

    .spa-input-box {
      float: left;

      .spa-input-label  {
        color: $color-gray;
        font-family: $cnx-family, $cnx-font-fallbacks;
        font-size: 20px;
        font-weight: normal;
        @media #{$medium-up} {
          font-size: 24px;
        }
        @media #{$large-up} {
          font-size: 28px;
        }
      }

      input#locationInputField {
        width: 150px;
        @media #{$medium-up} {
          width: auto;
        }
      }
    }
    #findLocationGoButton {
      margin-left: 5px;
      position: absolute;
      bottom: 46px;
    }

    .icon {
      text-align: right;

      .location-icon {
        height: 50px;
        width: auto;
        padding-right: 5px;
      }

      .feedback-icon {
        height: 30px;
        width: auto;
        padding-right: $spacing-8;
        margin-top: 6px;
      }
    }

    h3 {
      color: $color-gray;
      font-family: $cnx-family, $cnx-font-fallbacks;
      font-weight: normal;
      font-size: 20px;
      @media #{$medium-up} {
        font-size: 24px;
      }
      @media #{$large-up} {
        font-size: 28px;
      }
    }
  }
}
